import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import { FaApple } from 'react-icons/fa'; // Import Apple icon

const Home = () => {
  const heroRef = useRef(null);

  const loadVantaEffect = () => {
    if (!window.THREE) {
      const threeScript = document.createElement('script');
      threeScript.src = 'https://unpkg.com/three@0.134.0/build/three.min.js';
      threeScript.onload = () => {
        loadVanta();
      };
      document.body.appendChild(threeScript);
    } else {
      loadVanta();
    }
  };

  const loadVanta = () => {
    if (!window.VANTA) {
      const vantaScript = document.createElement('script');
      vantaScript.src = 'https://unpkg.com/vanta/dist/vanta.globe.min.js';
      vantaScript.onload = () => {
        applyVanta();
      };
      document.body.appendChild(vantaScript);
    } else {
      applyVanta();
    }
  };

  const applyVanta = () => {
    window.VANTA.GLOBE({
      el: heroRef.current,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0xff00ff,
      backgroundColor: 0x000000,
      backgroundAlpha: 0.0,
    });
  };

  useEffect(() => {
    loadVantaEffect();

    return () => {
      if (window.VANTA) {
        if (window.VANTA.animations) {
          window.VANTA.animations.forEach((animation) => animation.destroy());
          window.VANTA.animations = [];
        }
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style jsx global>{`
              @keyframes pulse {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.05);
          }
          100% {
            transform: scale(1);
          }
        }
              .vanta-canvas {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
        .HERO-titleButtonStyle {
          padding: 20px 40px;
          background-color: #b8a9c9;
          color: white;
          font-weight: bold;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 1.4rem;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2), 0px 6px 20px rgba(218, 165, 32, 0.15);
          text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
          transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
          flex-grow: 1; /* Allow buttons to grow equally */
          text-align: center; /* Ensure text is centered */
          max-width: none;
        }

        .HERO-titleButtonStyle:hover {
          box-shadow: 0px 0px 20px rgba(184, 169, 201, 0.8), 0px 0px 30px rgba(218, 165, 32, 0.5);
          background-color: #9e89b3;
        }

        .HERO-iosButtonStyle {
          background-color: #0070ff;
          animation: pulse 2s infinite;
          display: flex; /* Flex to align items */
          align-items: center;
          justify-content: center;
          width: 80%; /* Default width */
          max-width: 400px; /* Set a max width for larger screens */
          margin: 0 auto; /* Center the button */
          padding: 10px 20px;
          color: white;
          font-weight: bold;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 1.4rem;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2), 0px 6px 20px rgba(218, 165, 32, 0.15);
          text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
          max-width: none;
        }

        .HERO-iosButtonStyle .icon {
          margin-right: 10px; /* Space between logo and text */
        }

        .HERO-iosButtonStyle:hover {
          background-color: #0056b3;
          box-shadow: 0px 0px 30px rgba(0, 112, 255, 0.8), 0px 0px 30px rgba(255, 255, 255, 0.4);
        }


        .HERO-button-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 20px; /* Added margin-bottom */
          width: 50%; /* Default width */
          max-width: 600px; /* Set a max width */
          gap: 10px; /* Space between buttons */
          margin: 0 auto 20px auto; /* Center the container and add bottom margin */
        }


        .HERO-footerLinkStyle {
          color: white;
          text-decoration: none;
          cursor: pointer;
          font-size: 18px;
          margin: 0 0 5px 0;
        }

        .HERO-footerLinkStyle:hover {
          color: blue;
        }

        .HERO-main-hero-container {
          display: flex;
          position: relative;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: white;
          padding: 0 100px;
          text-align: center;
          height: 550px;
          width: 100vw;
          background: linear-gradient(90deg, rgba(255,0,150,0.7), rgba(0,204,255,0.7));
        }

        .HERO-main-subtitle {
          font-size: 1.5rem;
          margin-bottom: 40px;
          color: #fff;
          text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
        }

        .HERO-main-title {
          font-size: 4rem;
          font-weight: bold;
          margin-bottom: 20px;
          color: #fff;
          text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
        }







        /* Responsive widths */
        @media (max-width: 1300px) {
          .HERO-button-container {
            width: 60%;
          }
        }
         @media (max-width: 1150px) {
          .HERO-button-container {
            width: 70%;
          }
        }

        @media (max-width: 1000px) {
          .HERO-titleButtonStyle {
            padding: 15px 30px;
            font-size: 1.2rem; /* Smaller font size */
          }
          .HERO-iosButtonStyle {
            font-size: 1.2rem; /* Smaller font size */
          }
        }
        @media (max-width: 850px) {
          .HERO-button-container {
            width: 75%;
          }
        }
        @media (max-width: 800px) {
          .HERO-main-hero-container {
            padding: 40px 20px;
            height: auto;
          }

          .HERO-main-subtitle {
            font-size: 1.3rem;
          }

          .HERO-main-title {
            font-size: 3.5rem;
          }

          .HERO-titleButtonStyle {
            padding: 15px 30px;
            font-size: 1.2rem;
          }
        }

        /* New Media Query for 600px */
        @media (max-width: 600px) {
          .HERO-button-container {
            width: 80%;
          }

          .HERO-titleButtonStyle {
            font-size: 1rem; /* Font size for smaller screens */
            padding: 10px 20px; /* Adjust padding */
          }
          .HERO-iosButtonStyle {
            width: 90%;
          }
        }

        @media (max-width: 480px) {
          .HERO-titleButtonStyle {
            padding: 6px 12px;
            font-size: 0.7rem; /* Even smaller font size */
          }
        }

        @media (max-width: 400px) {
          .HERO-button-container {
            width: 90%;
          }
           .HERO-main-hero-container {
            padding: 30px 20px;
            height: auto;
          }

          .HERO-main-subtitle {
            font-size: 1rem;
          }

          .HERO-titleButtonStyle {
            padding: 6px 12px;
            font-size: 0.7rem;
          }

          .HERO-main-title {
            font-size: 2.5rem;
          }
      }

      `}</style>

      <div ref={heroRef} className="HERO-main-hero-container">
        <DynamicTypingHeading />
        <p className="HERO-main-subtitle">Your Financial Journey, Amplified by Our Technology.</p>
        <div className="HERO-button-container">
          <a href="https://x.com/pinxtoken" className="HERO-titleButtonStyle">Twitter</a>
          <a href="https://t.me/pinxglobalmarket" className="HERO-titleButtonStyle">Telegram</a>
          
          <a href="https://dexscreener.com/solana/6m32vkef22fzvv2nc69wnabdax7wdn4zsggwjujurqev" className="HERO-titleButtonStyle">Buy $PINX</a>
        </div>
        <div className="HERO-button-container">
          <a href="https://apps.apple.com/us/app/pinx-global-markets/id6670228875" className=" HERO-iosButtonStyle">
            <FaApple className="icon" size={24} /> 
            Now Available on iOS
          </a>
        </div>
      </div>

    </>
  );
};

export default Home;

const DynamicTypingHeading = () => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['PINX GLOBAL MARKETS'],
      typeSpeed: 30,
      backSpeed: 50,
      backDelay: 1500,
      startDelay: 300,
      loop: false,
      showCursor: true,
      cursorChar: '',
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return <div ref={el} className="HERO-main-title"></div>;
};
