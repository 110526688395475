import React, { useState } from 'react';
import Image from 'next/image';

const Services: React.FC = () => {
  const [hoveredCardId, setHoveredCardId] = useState(null);

  const cards = [
    {
      id: 'buy-crypto',
      src: '/assets/buy.png',
      title: 'Buy Crypto',
      description: 'Purchase crypto quickly and easily on our popular and industry-leading platform.',
    },
    {
      id: 'spot-trading',
      src: '/assets/bitcoin.png',
      title: 'Spot Trading',
      description: 'Trade crypto with our comprehensive set of powerful tools to maximize your profits.',
    },
    {
      id: 'crypto-derivatives',
      src: '/assets/socks.png',
      title: 'Crypto Derivatives',
      description: 'We are the best crypto exchange for trading crypto futures.',
    },
    {
      id: 'margin-trading',
      src: '/assets/laptop.png',
      title: 'Margin Trading',
      description: 'Borrow, trade, and repay. Leverage your assets with margin trading.',
    }
  ];

  return (
    <div className="services-container">
      <h2 className="services-heading">Explore Pinx Products & Services</h2>
      <div className="services-grid">
        {cards.map((card) => (
          <div 
            key={card.id} 
            className="services-card"
            onMouseEnter={() => setHoveredCardId(card.id)}
            onMouseLeave={() => setHoveredCardId(null)}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div className="services-image">
                <Image src={card.src} alt={card.title} layout="fill" objectFit="cover" />
              </div>
              <div>
                <div className="services-title">{card.title}</div>
                <div className="services-description">{card.description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <style jsx global>{`
        .services-container {
          padding: 50px;
          background: linear-gradient(90deg, rgba(255,0,150,0.7), rgba(0,204,255,0.7));
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .services-heading {
          color: white;
          text-align: center;
          margin-bottom: 30px;
          font-size: 2.3rem;
          text-shadow: 3px 3px 6px rgba(0,0,0,0.5);
        }

        .services-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          width: 100%;
          max-width: 1200px;
        }

        .services-card {
          background: rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
          padding: 20px;
          text-align: center;
          color: white;
          transition: background 0.3s ease;
          cursor: pointer;
        }

        .services-card:hover {
          background: grey;
          backdrop-filter: none;
        }

        .services-image {
          position: relative;
          width: 100px;
          height: 100px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .services-title {
          font-weight: bold;
          font-size: 1.6rem;
          margin: 10px 0;
        }

        .services-description {
          font-size: 1.2rem;
          margin: 10px 0;
        }

        @media (max-width: 600px) {
          .services-grid {
            grid-template-columns: 1fr;
          }

          .services-heading {
            font-size: 1.8rem;
          }

          .services-title {
            font-size: 1.4rem;
          }

          .services-description {
            font-size: 1rem;
          }

          .services-image {
            width: 80px;
            height: 80px;
          }
        }

        @media (max-width: 400px) {
          .services-heading {
            font-size: 1.5rem;
          }

          .services-title {
            font-size: 1.2rem;
          }

          .services-description {
            font-size: 0.9rem;
          }

          .services-card {
            padding: 10px;
            width: calc(100% - 20px);
          }

          .services-container {
            padding: 30px;
          }

          .services-image {
            width: 60px;
            height: 60px;
          }
        }
      `}</style>
    </div>
  );
};

export default Services;
