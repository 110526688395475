import React from 'react';
import Image from 'next/image';

const StepsToStartTrading: React.FC = () => {
  return (
    <div id="steps" className="STEPS-container">
      <div className="STEPS-heading">3 Simple Steps to Start Trading Tokenized Stocks</div>
      <div className="STEPS-steps-container">
        <div className="STEPS-step">
          <div className="STEPS-image">
            <Image src="/assets/flap.png" alt="Download App" layout="fill" objectFit="contain" />
          </div>
          <div className="STEPS-step-heading">Download App</div>
          <div className="STEPS-description">
            Get the app from the App Store or Google Play and install it on your device.
          </div>
        </div>
        <div className="STEPS-step">
          <div className="STEPS-image">
            <Image src="/assets/digital-wallet.png" alt="Connect Wallet" layout="fill" objectFit="contain" />
          </div>
          <div className="STEPS-step-heading">Connect Your Solana Wallet</div>
          <div className="STEPS-description">
            Connect your Solana wallet to the app. Ensure your wallet is compatible and secured.
          </div>
        </div>
        <div className="STEPS-step">
          <div className="STEPS-image">
            <Image src="/assets/lucky.png" alt="Invest" layout="fill" objectFit="contain" />
          </div>
          <div className="STEPS-step-heading">Invest</div>
          <div className="STEPS-description">
            Buy, sell, and trade tokenized stocks directly from your wallet using our app.
          </div>
        </div>
      </div>

      <style jsx global>{`
        .STEPS-container {
          background: linear-gradient(90deg, rgba(255,0,150,0.7), rgba(0,204,255,0.7));
          color: white;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 50px 0;
          font-family: Arial, sans-serif;
        }

        .STEPS-heading {
          font-size: 3rem;
          font-weight: bold;
          margin: 10px 0;
          text-align: center;
          padding: 0 20px;
        }

        .STEPS-steps-container {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          width: 100%;
          margin-top: 20px;
        }

        .STEPS-step {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 300px;
          margin: 20px;
          text-align: center;
        }

        .STEPS-step-heading {
          font-size: 2rem;
          font-weight: bold;
          margin: 10px 0;
        }

        .STEPS-description {
          text-align: center;
          font-weight: bold;
        }

        .STEPS-button {
          background-color: #24b47e;
          color: white;
          border: none;
          border-radius: 20px;
          padding: 10px 20px;
          cursor: pointer;
          font-size: 1rem;
          margin: 20px 0;
        }

        .STEPS-image {
          position: relative;
          width: 100px;
          height: 100px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 800px) {
          .STEPS-heading {
            font-size: 2.5rem;
          }
          .STEPS-step-heading {
            font-size: 1.75rem;
          }
          .STEPS-button {
            font-size: 0.9rem;
          }
        }

        @media (max-width: 600px) {
          .STEPS-heading {
            font-size: 2rem;
          }
          .STEPS-step-heading {
            font-size: 1.5rem;
          }
          .STEPS-button {
            font-size: 1.2rem;
            padding: 15px 30px;
          }
        }

        @media (max-width: 500px) {
          .STEPS-heading {
            font-size: 1.5rem;
          }
          .STEPS-step-heading {
            font-size: 1.25rem;
          }
          .STEPS-button {
            font-size: 0.8rem;
            padding: 8px 16px;
          }
          .STEPS-image {
            width: 80px;
            height: 80px;
          }
        }
      `}</style>
    </div>
  );
};

export default StepsToStartTrading;
