import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const AboutSlider: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 8;
  const sliderRef = useRef<any>(null);
  const [isPaused, setIsPaused] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
    customPaging: i => (
      <div
        className={`AboutSlider-customPaging ${currentSlide === i ? 'AboutSlider-active' : ''}`}
      >
        {i + 1}
      </div>
    ),
    appendDots: dots => (
      <div className="AboutSlider-dotsContainer">
        <div className="AboutSlider-appendDots">
          <ul className="AboutSlider-dots"> {dots} </ul>
        </div>
      </div>
    ),
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isPaused) {
        const nextSlide = (currentSlide + 1) % totalSlides;
        setCurrentSlide(nextSlide);
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(nextSlide);
        }
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentSlide, isPaused, totalSlides]);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  return (
    <div className="AboutSlider-sliderContainer" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div style={{ height: '100%' }}>
        <Slider ref={sliderRef} {...settings}>
          {[...Array(totalSlides)].map((_, idx) => (
            <div key={idx} className="AboutSlider-container">
              {idx === 0 && (
                <div className="AboutSlider-adjustedContainer">
                  <div className="AboutSlider-leftSection">
                    <h1 className="AboutSlider-heading">PINX Exchange: Revolutionizing Stock Investment</h1>
                    <p className="AboutSlider-paragraph">Dive into the future with PINX Exchange, where we merge technology with finance to offer tokenized stocks, unlocking a new dimension of investment possibilities.</p>
                  </div>
                  <div className="AboutSlider-rightSection">
                    <div className="AboutSlider-imageContainer">
                      <Image src="/assets/growth.png" alt="Growth" layout="fill" objectFit="cover" />
                    </div>
                  </div>
                </div>
              )}

              {idx === 1 && (
                <div className="AboutSlider-adjustedContainer">
                  <div className="AboutSlider-rightSection">
                    <div className="AboutSlider-imageContainer">
                      <Image src="/assets/vision.png" alt="Growth" layout="fill" objectFit="cover" />
                    </div>
                  </div>
                  <div className="AboutSlider-leftSection">
                    <h1 className="AboutSlider-heading">Tokenized Stocks: The Future is Here</h1>
                    <p className="AboutSlider-paragraph">Experience the forefront of financial innovation with PINX Exchange. Our tokenized solutions transform traditional stock trading, enhancing liquidity, and efficiency while ensuring a seamless investment experience.</p>
                  </div>
                </div>
              )}

              {idx === 2 && (
                <div className="AboutSlider-adjustedContainer">
                  <div className="AboutSlider-leftSection">
                    <h1 className="AboutSlider-heading">Global Access Unlocked</h1>
                    <p className="AboutSlider-paragraph">PINX Exchange erases borders, granting you direct entry into the U.S. penny stock market and beyond. Invest from anywhere, anytime, and expand your portfolio without limitations.</p>
                  </div>
                  <div className="AboutSlider-rightSection">
                    <div className="AboutSlider-imageContainer">
                      <Image src="/assets/key.png" alt="Growth" layout="fill" objectFit="cover" />
                    </div>
                  </div>
                </div>
              )}

              {idx === 3 && (
                <div className="AboutSlider-adjustedContainer">
                  <div className="AboutSlider-rightSection">
                    <div className="AboutSlider-imageContainer">
                      <Image src="/assets/handshake.png" alt="Growth" layout="fill" objectFit="cover" />
                    </div>
                  </div>
                  <div className="AboutSlider-leftSection">
                    <h1 className="AboutSlider-heading">Empowering Investors with Security and Trust</h1>
                    <p className="AboutSlider-paragraph">Invest with confidence on a platform that places your security first. Regulated by U.S. financial authorities, PINX Exchange employs advanced encryption and fraud detection systems to protect your investments and privacy.</p>
                  </div>
                </div>
              )}

              {idx === 4 && (
                <div className="AboutSlider-adjustedContainer">
                  <div className="AboutSlider-leftSection">
                    <h1 className="AboutSlider-heading">Invest More, Pay Less</h1>
                    <p className="AboutSlider-paragraph">Why let high fees diminish your returns? At PINX Exchange, we champion low transaction fees, empowering you to make the most of your investments. Affordable trading is no longer a dream—it&apos;s your new reality.</p>
                  </div>
                  <div className="AboutSlider-rightSection">
                    <div className="AboutSlider-imageContainer">
                      <Image src="/assets/invoice.png" alt="Growth" layout="fill" objectFit="cover" />
                    </div>
                  </div>
                </div>
              )}

              {idx === 5 && (
                <div className="AboutSlider-adjustedContainer">
                  <div className="AboutSlider-rightSection">
                    <div className="AboutSlider-imageContainer">
                      <Image src="/assets/coins.png" alt="Growth" layout="fill" objectFit="cover" />
                    </div>
                  </div>
                  <div className="AboutSlider-leftSection">
                    <h1 className="AboutSlider-heading">The Power of Penny Stocks</h1>
                    <p className="AboutSlider-paragraph">Unlock the vast potential of the penny stock market with PINX Exchange. Our platform opens the door to high-growth opportunities previously inaccessible to many investors, providing a new path to diversification and returns.</p>
                  </div>
                </div>
              )}

              {idx === 6 && (
                <div className="AboutSlider-adjustedContainer">
                  <div className="AboutSlider-leftSection">
                    <h1 className="AboutSlider-heading">Beyond Investing: A Financial Revolution</h1>
                    <p className="AboutSlider-paragraph">PINX Exchange isn&apos;t just a platform; it&apos;s a revolution. By democratizing access to global stock markets and fostering financial inclusion, we&apos;re not just changing the game—we&apos;re creating a new one.</p>
                  </div>
                  <div className="AboutSlider-rightSection">
                    <div className="AboutSlider-imageContainer">
                      <Image src="/assets/team.png" alt="Growth" layout="fill" objectFit="cover" />
                    </div>
                  </div>
                </div>
              )}

              {idx === 7 && (
                <div className="AboutSlider-adjustedContainer">
                  <div className="AboutSlider-rightSection">
                    <div className="AboutSlider-imageContainer">
                      <Image src="/assets/network-connection.png" alt="Growth" layout="fill" objectFit="cover" />
                    </div>
                  </div>
                  <div className="AboutSlider-leftSection">
                    <h1 className="AboutSlider-heading">Your Gateway to the Future of Finance</h1>
                    <p className="AboutSlider-paragraph">Join us at PINX Exchange as we pave the way for a future where investing is more accessible, transparent, and efficient than ever. Here, technology meets opportunity, and your financial goals are within reach.</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
      <style jsx global>{`
        .AboutSlider-sliderContainer {
          display: flex;
          flex-direction: column;
          height: 100%;
          background: linear-gradient(90deg, rgba(255,0,150,0.7), rgba(0,204,255,0.7));
          padding: 20px;
          border-radius: 8px;
          box-sizing: border-box;
        }

        .AboutSlider-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 20px;
          background-color: transparent;
          color: white;
          box-sizing: border-box;
        }

        .AboutSlider-adjustedContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 20px;
          background-color: transparent;
          color: white;
          box-sizing: border-box;
        }

        .AboutSlider-leftSection {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 50%;
          text-align: center;
          align-items: center;
        }

        .AboutSlider-rightSection {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 50%;
          overflow: hidden;
        }

        .AboutSlider-imageContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 200px;
          height: 200px;
          overflow: hidden;
        }

        .AboutSlider-heading {
          font-size: 2em;
          color: white;
          background-color: #D02670;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          padding: 10px;
          border-radius: 5px;
        }

        .AboutSlider-paragraph {
          font-size: 1.3em;
          text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
          width: 80%;
        }

        .AboutSlider-customPaging {
          color: white;
          opacity: 0.5;
        }

        .AboutSlider-active {
          font-weight: bold;
          color: white;
          opacity: 1;
        }

        .AboutSlider-dotsContainer {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }

        .AboutSlider-appendDots {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .AboutSlider-dots {
          display: flex;
          margin: 0;
        }

        @media (max-width: 768px) {
          .AboutSlider-container, .AboutSlider-adjustedContainer, .AboutSlider-leftSection, .AboutSlider-rightSection {
            flex-direction: column;
            width: 100%;
            text-align: center;
            padding: 10px;
          }

          .AboutSlider-leftSection, .AboutSlider-rightSection {
            width: 100%;
          }

          .AboutSlider-imageContainer {
            width: 100%;
            height: auto;
          }

          .AboutSlider-heading {
            font-size: 1.5em;
          }

          .AboutSlider-paragraph {
            font-size: 1em;
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default AboutSlider;
