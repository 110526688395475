import React, { useState } from 'react';

const Footer: React.FC = () => {

  return (
    <>
      {belowFooter}
    </>
  );
};

export default Footer;

const belowFooter = (
  <div style={{ borderTop: '1px solid white', color: 'white', padding: '10px', textAlign: 'center', background: 'linear-gradient(135deg, #5b0000, #000000)',}}>
    © 2023-2024 PINX.com. All rights reserved.
  </div>
);


